import { v4 as uuidv4 } from 'uuid'
import firegramImg from '../images/firegram.PNG'
import TrainOffendersAppImg from '../images/mobile.jfif'
import LibraryImg from '../images/library.png'
import UTrackerImg from '../images/utracker.jpg'
import ElearningImg from '../images/elearningpng.png'
import covid19trackerImg from '../images/covi19.jpeg'
import darnImg from '../images/darna.PNG'
import quizzImg from '../images/quizz.jpg'

const projects = [
   {
      id: uuidv4(),
      name: 'Smart Business',
      desc:
         'A monitoring system for restaurants and delivery people. This system allow restaurant owners to manage all their orders coming from different food providers in one place with other bunch of features like manage stocks, menus, suppliers and generate reports',
      img: UTrackerImg,
   },
   {
      id: uuidv4(),
      name: 'E-learning Platform',
      desc:
         'An platfoem for helpING people to learn online. This platform contain a dashboading part that shows detailed statistics for the admin',
      img: ElearningImg,
   },
   {
      id: uuidv4(),
      name: 'Covid-19 Tracker',
      desc:
         'Using this app you can track covid-19 cases around the world. You have the ability to track cases by country or worldwidely. This web app contain charts that shows cases evolution overtime',
      img: covid19trackerImg,
   },
   {
      id: uuidv4(),
      name: 'Quizz Maker App',
      desc:
         'Using this app you can create, update, delete a quizz and comment on any question in any quizz. For sure, you can answer on any quizz if you log in as a player ',
      img: quizzImg,
   },
   {
      id: uuidv4(),
      name: 'Train Offenders Catcher',
      desc:
         'A mobile app for managing train offenders and a backoffice to manage data coming from this app with a dashboarding part to show data collected clearly',
      img: TrainOffendersAppImg,
   },
   {
      id: uuidv4(),
      name: 'Library Management App',
      desc:
         'A web app to manage books and reservation. As a user, you can rent a book for a well determined period. As an admin, you can manage books rentals and books   ',
      img: LibraryImg,
   },

   {
      id: uuidv4(),
      name: 'Darna Association Website Remake',
      desc:
         'Remaking the official website of the association darna in order to improve the design and adding new features',
      img: darnImg,
   },

   {
      id: uuidv4(),
      name: 'Firegram',
      desc:
         'Inspired from unsplash. Firegram is a place where you can add your photos',
      img: firegramImg,
   },
]

export default projects
