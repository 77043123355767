const testimonials = [
   {
      id: 1,
      name: 'Design Responsible',
      title: 'Club Jeunes Ingénieurs ISAMM',
      // org: '---',
      desc:
         'Designing event flyers and badges, social media posts and banners and participating to video editing process inside the design team',
   },
   {
      id: 2,
      name: 'Communication Responsible',
      title: 'FSEGN Microsoft Tech Club',
      desc:
         'Overseeing all internal and external communications, ensuring the engagment and the consistence of any communication and preparing detailed media reports and marketing materials  ',
   },
   {
      id: 3,
      name: 'Marketing Team Member',
      title: 'FSEGN Junior Entreprise',
      // org: 'Eleven Dawn',
      desc: 'Particpating in market study process ',
   },
]
export default testimonials
