import React from 'react'
import styled from 'styled-components'
import PText from '../components/PText'
import DownloadBtn from '../components/DownloadBtn'
import AboutImg from '../assets/images/about-page-img.png'
import AboutInfoItem from '../components/AboutInfoItem'
import ContactBanner from '../components/ContactBanner'

import myResume from '../assets/data/majedbannouri_resume.pdf'

const AboutPageStyles = styled.div`
   padding: 20rem 0 10rem 0;

   .top-section {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
   }
   .left {
      flex: 3;
   }
   .right {
      flex: 2;
   }
   .about__subheading {
      font-size: 2.2rem;
      margin-bottom: 2rem;
      span {
         background-color: var(--deep-dark);
         padding: 0.5rem;
         border-radius: 8px;
      }
   }
   .about__heading {
      font-size: 3.6rem;
      margin-bottom: 3rem;
   }
   .about__info {
      margin-bottom: 4rem;
      .para {
         max-width: 100%;
      }
   }
   .right {
      img {
         border: 2px solid var(--gray-1);
      }
   }
   .about__info__items {
      margin-top: 15rem;
   }
   .about__info__item {
      margin-bottom: 10rem;
   }
   .about__info__heading {
      font-size: 3.6rem;
      text-transform: uppercase;
   }
   @media only screen and (max-width: 768px) {
      padding: 10rem 0;
      .top-section {
         flex-direction: column;
         gap: 5rem;
      }
      .about__subheading {
         font-size: 1.8rem;
      }
      .about__heading {
         font-size: 2.8rem;
      }
      .about__info__heading {
         font-size: 3rem;
      }
   }
`

export default function About() {
   return (
      <>
         <AboutPageStyles>
            <div className="container">
               <div className="top-section">
                  <div className="left">
                     <p className="about__subheading">
                        Hi, I am <span>Majed Bannouri</span>
                     </p>
                     <h2 className="about__heading">A Software Engineer</h2>
                     <div className="about__info">
                        <PText>
                           I am a software engineer from Tunisia. Since my
                           childhood, I love art and design. I always try to
                           design stuff with my unique point of view. I also
                           love to create things that can be usefull to others.
                           <br />
                           <br />
                           My vision is to make the world a better place. Now
                           almost everything is becoming better than ever. It is
                           time for us to create more good stuff that helps the
                           world to become a better place.
                        </PText>
                     </div>
                     <DownloadBtn
                        btnText="Download Resume"
                        btnLink={myResume}
                     />
                  </div>
                  <div className="right">
                     <img src={AboutImg} alt="me" />
                  </div>
               </div>
               <div className="about__info__items">
                  <div className="about__info__item">
                     <h1 className="about__info__heading">Education</h1>

                     <AboutInfoItem
                        title="University"
                        items={[
                           'Software Engineering, Higher Institute of Multimedia Arts of Manouba',
                        ]}
                     />
                     <AboutInfoItem title=" " items={[]} />
                     <AboutInfoItem
                        title=" "
                        items={[
                           'Computing Applied To Management, Faculty of Economic Science and Management of Nabeul',
                        ]}
                     />
                     <AboutInfoItem title=" " items={[]} />

                     {/* <AboutInfoItem
                        title="Varsity"
                        items={['University Of Chitiagong']}
                     /> */}
                  </div>
                  <div className="about__info__item">
                     <h1 className="about__info__heading">My Skills</h1>

                     <AboutInfoItem
                        title="FrontEnd"
                        items={[
                           'HTML',
                           'CSS',
                           'JavaScript',
                           'TypeScript',
                           'REACT',
                           'Tailwind',
                        ]}
                     />
                     <AboutInfoItem
                        title="BackEnd"
                        items={['Node', 'Express', 'PHP', 'Java']}
                     />
                     <AboutInfoItem
                        title="Design"
                        items={['Photoshop', 'Illustartor', 'Figma']}
                     />
                  </div>
                  <div className="about__info__item">
                     <h1 className="about__info__heading">Experiences</h1>

                     <AboutInfoItem
                        title="2021"
                        items={[
                           'Full Stack Developer [Internship] @ BestWebMobile',
                        ]}
                     />
                     <AboutInfoItem title="" items={[]} />
                     <AboutInfoItem
                        title="2019 "
                        items={[
                           'Front End Developer [Internship] @ Cosmos Services',
                        ]}
                     />
                     <AboutInfoItem title="" items={[]} />

                     <AboutInfoItem
                        title="2018"
                        items={['Full Stack Developer [Internship] @ SNCFT']}
                     />
                     <AboutInfoItem title="" items={[]} />

                     <AboutInfoItem
                        title="2017"
                        items={['Java Developer [Internship] @ BNA']}
                     />
                  </div>
               </div>
            </div>
            <ContactBanner />
         </AboutPageStyles>
      </>
   )
}
